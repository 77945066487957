import { RequestData as LocationQrCodeRequest } from 'api/services/LocationQrCode.types';
import { RequestData as RemittanceSheetRequest } from 'api/services/RemittanceSheetService/types';
import stripCompanyId from 'api/utils/stripCompanyId';
import stripPhoneNumber from 'api/utils/stripPhoneNumber';
import stripUrl from 'api/utils/stripUrl';
import { AffiliationValues } from 'components/Affiliation/types';
import { ContactFormValues } from 'components/ContactUs/types';
import { EmployeeFormValues } from 'components/Employees/types';
import { getDiffAssignedLocations } from 'components/Employees/utils';
import { BusinessFormValues } from 'components/Locations/types';
import {
  IsEnabledFn as IsPaymentEnabledFn,
  OnboardingValues,
  PaymentOptionEnum,
  PaymentTypeCodeEnum,
  POSValues,
} from 'components/Onboarding/types';
import { IdentificationFormValues } from 'components/Payments/types';
import { ChangeLocations } from 'components/Products/types';
import { RemittanceSheetFormValues } from 'components/RemittanceSheet/types';
import { filterPaperProduct } from 'components/RemittanceSheet/utils';
import { FormikErrors, FormikValues } from 'formik';
import {
  AffiliationConfirmRequest,
  AffiliationRequest,
  ContactFormRequest,
  Contract,
  ContractProduct,
  ContractProductRequest,
  ContractSummary,
  EmployeeLocationsRequest,
  EmployeeRequest,
  Location,
  LocationActivity,
  LocationBusinessRequest,
  LocationPaymentRequest,
  LocationPaymentType,
  LocationRequest,
  LocationsProductRequest,
  LocationTypeEnum,
  PayoutChannel,
  PersonOption,
  PosProductsRequest,
  PosRequest,
  PosTransferRequest,
  PosTypeEnum,
  Product,
  ProductFull,
  ProductItem,
  TransactionClaimRequest,
} from 'generated/models';
import { TranslateFn } from 'i18n';
import { get } from 'lodash';
import { SessionUserType } from 'types/common';
import insertIf from 'utils/insertIf';

// TODO: rewrite to be handled by ContactInfoStep?
const buildPerson = (
  values: AffiliationValues,
  currentUser?: SessionUserType,
  forceSignatory = true,
): PersonOption => {
  const userNotSignatory = !(forceSignatory ? true : values.signatoryPerson) && currentUser;
  return {
    companyPositionId: userNotSignatory ? values.contactPosition! : values.position!,
    email: currentUser?.email ?? values.email,
    firstName: currentUser?.firstName ?? values.firstName,
    lastName: currentUser?.lastName ?? values.lastName,
    phone: stripPhoneNumber(currentUser?.telephone ?? values.phoneNumber),
    signatory: forceSignatory ? true : values.signatoryPerson,
    mainLanguage: (userNotSignatory ? values.contactLanguage : values.language) || undefined,
  };
};

const hasPaymentOption = (p: PaymentOptionEnum, options: PaymentOptionEnum[]) =>
  options.includes(p);

export const buildAffiliation = (
  values: AffiliationValues,
  currentUser: SessionUserType,
  isBOUser: boolean,
): AffiliationRequest => ({
  invoicingEmail: values.invoiceEmail || undefined,
  bankAccount: {
    ...(values.bank
      ? {
          bankId: values.bank || undefined,
          accountNumber: values.bankNumber
            ? `${values.bankNumber}-${values.accountNumber}`
            : values.accountNumber,
        }
      : {
          accountIban: values.accountNumber,
        }),
    purchaseOrderNumber: values.poReferenceNumber || undefined,
  },
  company: {
    masterId: values.masterId || undefined,
    cin: stripCompanyId(values.companyId),
    vatin: values.vatNumber || undefined,
    vatPayer: values.vatPayer,
    name: values.companyName,
    nameCommercial: values.commercialName || undefined,
    memberId: values.campaignMemberId || undefined,
    legalForm: values.legalForm || undefined,
    regReference: values.courtFileNumber || undefined,
    addrHeadquarters: {
      city: values.city,
      street: values.streetName,
      zip: values.postalCode,
      houseNumber: values.houseNumber,
      box: values.box || undefined,
    },
  },
  // logged user
  contactPerson: isBOUser ? buildPerson(values) : buildPerson(values, currentUser, false),
  // if logged user not marked as signatory
  contactPersonsOthers: isBOUser || values.signatoryPerson ? undefined : [buildPerson(values)],
  products: values.products.map(
    ({ productCode, payoutChannelCode, payoutFrequencyCode, priceTierCode }) => ({
      productCode,
      payoutChannelCode,
      payoutFrequencyCode,
      priceTierCode,
    }),
  ),
  partnerAccounts: Object.keys(values.partnerAccounts)
    .filter(
      (partnerKey) =>
        partnerKey &&
        values.partnerAccounts[partnerKey] &&
        values.products.some((product) => product.partnerKey === partnerKey),
    )
    .map((partnerKey) => ({ partnerKey, accountNumber: values.partnerAccounts[partnerKey] })),
  services: values.activity,
  files: values.powerOfAttorney ? [values.powerOfAttorney] : undefined,
});

export const buildAffiliationConfirm = (values: AffiliationValues): AffiliationConfirmRequest => ({
  files: values.powerOfAttorney ? [values.powerOfAttorney] : undefined,
});

export const buildPOS = (pos: POSValues): PosRequest => {
  switch (pos.country) {
    case 'cz': {
      const isAppType = pos.deviceType === PosTypeEnum.App;
      return {
        pos: {
          type: pos.deviceType,
          physicalTerminalId: pos.terminalId,
          productIds: pos.productIds,
          bankId: pos.bankId ? parseInt(pos.bankId, 10) : undefined,
          email: isAppType ? pos.email || undefined : undefined,
        },
      };
    }
    case 'be':
      return {
        pos: {
          type: PosTypeEnum.Pos,
          physicalTerminalId: pos.terminalId,
          provider: pos.terminalProvider,
        },
      };
    default:
      throw 'Unsupported country code';
  }
};

export const buildPOSes = (
  values: OnboardingValues,
  isPaymentTypeEnabled?: IsPaymentEnabledFn,
): PosRequest[] => {
  switch (values.country) {
    case 'cz':
      return values.POS.map((pos) => buildPOS(pos));
    case 'be': {
      if (
        isPaymentTypeEnabled?.(PaymentTypeCodeEnum.Terminal, values.products, values.type) &&
        hasPaymentOption(PaymentOptionEnum.POS, values.paymentOptions)
      )
        return values.POS.map((pos) => buildPOS(pos));
      return [];
    }
    default:
      throw 'Unsupported country code';
  }
};

export const buildPOSProducts = (pos: POSValues): PosProductsRequest => {
  switch (pos.country) {
    case 'cz':
      return { products: pos.productIds };
    case 'be':
      return { products: [] };
    default:
      throw 'Unsupported country code';
  }
};

export const buildPOSTransfer = (values: FormikValues): PosTransferRequest => ({
  locationMasterId: values.locationMasterId,
});

export const buildLocationActivity = (activityUid: string): LocationActivity => ({
  activityUid,
});

export const buildPaymentType = (
  code: PaymentTypeCodeEnum,
  provider?: string,
  number?: string,
): LocationPaymentType => ({
  typeUid: code.toString(),
  provider,
  number,
});

export const buildLocation = (
  values: OnboardingValues,
  isPaymentTypeEnabled?: IsPaymentEnabledFn,
): LocationRequest => {
  switch (values.country) {
    case 'cz':
      return {
        location: {
          name: values.name,
          wifi: values.wifi || undefined,
          website: stripUrl(values.webSiteUrl || undefined),
          email: values.email,
          phone: values.phoneNumber,
          phoneSecondary: values.secondaryPhoneNumber || undefined,
          address: {
            city: values.city,
            street: values.streetName,
            zip: values.postalCode,
            houseNumber: values.houseNumber,
          },
          activities: values.activities.map((activity) =>
            buildLocationActivity(activity.terActivity),
          ),
          productIds: values.products,
          merchantLocationId: values.merchantLocationId || undefined,
        },
      };
    case 'be': {
      // TODO: ugly improve
      const type = values.type as LocationTypeEnum;
      const isMarketplace = type === LocationTypeEnum.Marketplace;
      const isOnline = type === LocationTypeEnum.Online;
      const isPhysical = type === LocationTypeEnum.Physical;
      return {
        location: {
          type,
          name: values.locationName,
          website: isOnline || isPhysical ? stripUrl(values.website || undefined) : undefined,
          email: ((isMarketplace || isOnline) && values.email) || undefined,
          phone: values.phoneNumber,
          address: isPhysical
            ? {
                city: values.city,
                street: values.streetName,
                zip: values.postalCode,
                houseNumber: values.houseNumber,
                box: values.box || undefined,
              }
            : undefined,
          activities: values.typeOfActivity ? [buildLocationActivity(values.typeOfActivity)] : [],
          productIds: values.products.filter((product) =>
            isPaymentTypeEnabled?.(null, [product], values.type),
          ),
          merchantLocationId: undefined,
          // TODO: handle in more generic way
          paymentTypes: [
            ...insertIf(
              // is paymentType valid for selected products and locationType?
              isPaymentTypeEnabled?.(PaymentTypeCodeEnum.Payconiq, values.products, values.type) &&
                // is paymentType even selected?
                hasPaymentOption(PaymentOptionEnum.Payconiq, values.paymentOptions),
              // => build it
              buildPaymentType(PaymentTypeCodeEnum.Payconiq),
            ),
            ...insertIf(
              isPaymentTypeEnabled?.(PaymentTypeCodeEnum.PSP, values.products, values.type) &&
                Boolean(values.terminalIdOnline),
              buildPaymentType(
                PaymentTypeCodeEnum.PSP,
                values.terminalProviderOnline,
                values.terminalIdOnline,
              ),
            ),
            ...insertIf(
              isPaymentTypeEnabled?.(
                PaymentTypeCodeEnum.Marketplace,
                values.products,
                values.type,
              ) && hasPaymentOption(PaymentOptionEnum.Marketplace, values.paymentOptions),
              buildPaymentType(PaymentTypeCodeEnum.Marketplace),
            ),
          ],
          bankAccount: values.accountNumber ? { accountIban: values.accountNumber } : undefined,
        },
      };
    }
    default:
      throw 'Unsupported country code';
  }
};

export const buildLocationBusiness = (values: BusinessFormValues): LocationBusinessRequest => ({
  location: {
    name: values.name,
    wifi: values.wifi,
    website: stripUrl(values.website),
    email: values.email,
    phone: values.phone,
    phoneSecondary: values.phoneSecondary,
    merchantLocationId: values.merchantLocationId,
  },
});

export const buildLocationPayment = (values: IdentificationFormValues): LocationPaymentRequest => ({
  accountId: values.accountNumber.replace(/\s/g, ''), // strip whitespaces
  amount: Number(values.amount),
  note: values.note || undefined,
});

export const buildEmployee = (values: EmployeeFormValues): EmployeeRequest => ({
  person: {
    firstName: values.firstName,
    middleName: values.middleName,
    lastName: values.lastName,
    email: values.email,
    phone: values.phoneNumber,
    companyPositionId: values.position!, // TODO: wtf?
  },
  classification: values.isContactPerson
    ? {
        contact: values.isContactPerson,
        companyDepartmentId: values.contactDepartment || undefined,
        responsibilities: values.responsibility ? [values.responsibility] : undefined,
      }
    : undefined,
  portalAccess: values.hasNgmPortalAccess
    ? {
        access: values.hasNgmPortalAccess,
        userRole: values.ngmAccessRole,
        invitationEmail: values.invitationEmail,
      }
    : undefined,
  employeeLocations: buildEmployeeLocations(values).employeeLocations,
});

export const buildEmployeeLocations = (values: EmployeeFormValues): EmployeeLocationsRequest => ({
  employeeLocations: getDiffAssignedLocations(
    values.locationsContact,
    values.locationsAccess,
  ).filter((loc) => loc?.locationUid && (loc?.contact || loc?.editor)),
});

export const buildProduct = (
  product: Product | ProductFull,
  payout: PayoutChannel,
  accountNumber?: string,
): ContractProductRequest => ({
  product: {
    productCode: product.code,
    payoutChannelCode: payout.code ?? undefined,
    payoutFrequencyCode: payout.frequencyCode ?? undefined,
    priceTierCode: payout.priceTierCode,
  },
  partnerAccount:
    payout.parameterCode && accountNumber
      ? {
          partnerKey: payout.parameterCode,
          accountNumber,
        }
      : undefined,
});

export const buildProductLocations = (
  changeLocations: ChangeLocations,
): LocationsProductRequest => ({
  attach: changeLocations.toAdd,
  detach: changeLocations.toRemove,
});

export const buildTransactionClaim = (values: FormikValues): TransactionClaimRequest => ({
  amount: Number(values.amount),
});

export const buildLocationQrCode = (
  contract?: ContractSummary,
  location?: Location,
): LocationQrCodeRequest => ({
  location: location
    ? {
        name: location.name,
        address: location.address,
      }
    : undefined,
  contract: contract
    ? {
        name: contract.companyName,
        cin: contract.companyCin,
      }
    : undefined,
});

export const buildRemittanceSheet = (
  isBlank: boolean | undefined,
  t: TranslateFn,
  contract: Contract,
  values: RemittanceSheetFormValues,
  errors?: FormikErrors<RemittanceSheetFormValues>,
  products?: ContractProduct[],
): RemittanceSheetRequest => {
  const isLocationIdValid = !Boolean(get(errors, 'locationId', false)) && values.locationId;
  const isPhoneNumberValid = !Boolean(get(errors, 'phoneNumber', false)) && values.phoneNumber;
  const location = values.currentLocation;

  const mapProductToName = ({ product }: ProductItem | ContractProduct) => ({
    name: t(`product_details.products.*.${product.nameKey}.name`, product.nameKey),
    nameKey: product.nameKey,
  });

  return {
    location: location
      ? {
          name: location.name,
          address: location.address,
        }
      : undefined,
    contract: {
      address: contract.company?.addrHeadquarters,
      bankAccount: contract.bankAccount,
      masterId: contract.masterId ?? undefined,
      name: contract.company?.name,
      cin: contract.company?.cin,
      vatin: contract.company?.vatin,
    },
    phoneNumber: isPhoneNumberValid ? values.phoneNumber : undefined,
    products: isBlank
      ? isLocationIdValid
        ? location?.products?.filter(filterPaperProduct)?.map(mapProductToName)
        : products?.map(mapProductToName)
      : undefined,
    vouchers: isBlank
      ? undefined
      : values.vouchers.map((v) => ({
          nominalValue: v.nominalValue !== '' ? v.nominalValue : 0,
          vouchersCount: v.vouchersCount !== '' ? v.vouchersCount : 0,
          totalValue:
            v.nominalValue !== '' && v.vouchersCount !== '' && v.totalValue === ''
              ? v.nominalValue * v.vouchersCount
              : v.totalValue || 0,
          product: {
            name: t(`product_details.products.*.${v.productId}.name`, v.productId),
            nameKey: v.productId,
          },
        })),
  };
};

export const buildContactForm = (values: ContactFormValues): ContactFormRequest => ({
  form: {
    email: values.email,
    firstName: values.firstName,
    lastName: values.lastName,
    phone: values.phone,
    topic: values.topic,
    subtopic: values.subtopic,
    message: values.message,
  },
});
